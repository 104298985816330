<template>
  <b-row class="my-3">
    <b-col v-for="(widget, key) in widgets" :key="key">
      <b-card class="p-0 m-0 h-100">
        <div class="media">
          <div class="media-body">
            <span
              class="text-muted text-uppercase font-size-12 font-weight-bold"
            >
              {{ widget.name }}
            </span>
            <h2 v-if="isNaN(Number(widget.value))" class="mb-0">{{
              widget.value
            }}</h2>
            <h2 v-else class="mb-0">{{ widget.value | money }}</h2>
          </div>
          <div class="align-self-center">
            <span>
              <feather
                :type="`${widget.icon}`"
                class="icon-lg align-middle icon-dual-primary"
                :class="{
                  'icon-dual-warning': key === 1,
                  'icon-dual-success': key === 2,
                }"
              ></feather>
            </span>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'GameHistoryWidgets',
  props: {
    widgetsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      defaultWidgets: [
        {
          icon: 'activity',
          name: `${this.$t('pages.mini_games.total_games')}`,
          value: 0,
        },
        {
          icon: 'dollar-sign',
          name: `${this.$t('pages.mini_games.total_bet')}`,
          value: 0,
        },
        { icon: 'coffee', name: `${this.$t('games.total_income')}`, value: 0 },
      ],
    }
  },
  computed: {
    widgets() {
      return this.widgetsData.length > 0
        ? this.widgetsData
        : this.defaultWidgets
    },
  },
}
</script>
